/* eslint-disable */

import React from 'react'
import Tabs from './Tabs';
import logo from '../../src/Title.png';
import playButton from '../../src/Play_Button.png'

  function IndexView() {
    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ms-trendy-site-2a16b2.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-navbar-logo-left af-class-wf-section">
              <div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar-logo-left-container af-class-shadow-three w-nav">
                <div className="af-class-container-2">
                  <div className="af-class-navbar-wrapper">
                    <a href="https://theden.moonrunners.io/" className="af-class-navbar-brand w-nav-brand"><img src="images/den-logo.png" loading="lazy" width={50} alt className="af-class-image" /></a>
                    <nav role="navigation" className="af-class-nav-menu-wrapper w-nav-menu">
                      <ul role="list" className="af-class-nav-menu-two w-list-unstyled">
                        <li>
                          <a href="https://www.twitter.com/MoonrunnersNFT" className="af-class-link-block-twitter af-class-nav-link-2 w-inline-block" />
                        </li>
                        <li>
                          <a href="https://discord.gg/moonrunners" className="af-class-link-block af-class-nav-link-2 w-inline-block" />
                        </li>
                        <li>
                          <a href="https://opensea.io/collection/moonrunnersnft" className="af-class-link-block-2 af-class-nav-link-2 w-inline-block" />
                        </li>
                        <li className="af-class-mobile-margin-top-10" />
                      </ul>
                    </nav>
                    <div className="af-class-menu-button w-nav-button">
                      <div className="w-icon-nav-menu" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="logo">
                  <img src={logo} alt="Logo" />
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="play-button">
                  <a href="https://flappydragongame.vercel.app/">
                  <img src={playButton} alt="Play Button" />
                  </a>
                </div>
              </div>
            </div>
            <div className="af-class-section-2 af-class-wf-section">
              <div className="af-class-category w-container">
                <div>
                  <hr id="rounded"></hr>
                </div>
                  <Tabs />
              </div>
            </div>
            <div className="af-class-section-3 af-class-wf-section" />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }

export default IndexView;

/* eslint-enable */