import React, { useState, useEffect } from "react";

import "./table.css";

function LeaderboardDaily() {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    fetch(`https://21925.playfabapi.com/Server/GetLeaderboard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-SecretKey": process.env.REACT_APP_API_KEY
      },
      body: JSON.stringify({
        StatisticName: "Moonrunners-Daily",
        StartPosition: 0,
        MaxResultsCount: 100
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setLeaderboardData(data.data.Leaderboard);
      });
  }, []);

  return (
    <div className="af-class-shortcuts-table">
                  <div className="af-class-shortcut-row-header w-row">
                    <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div>RANK</div>
                    </div>
                    <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div>NAME</div>
                    </div>
                    <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div>SCORE</div>
                    </div>
                  </div>
      <div className="af-class-shortcut-row w-row">
        {leaderboardData.map((player, index) => (
          <div className="af-class-shortcut-row w-row trow" key={index}>
            <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">{player.Position + 1}</div>
            <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">{player.DisplayName}</div>
            <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">{player.StatValue}</div>
          </div>
        ))}
      </div>
    </div>
    
  );
}

export default LeaderboardDaily;
