import React, { useState } from "react";
import TabNavItem from "../V2/TabNavItem";
import TabContent from "../V2/TabContent";
import LeaderboardAllTime from "./LeaderboardAllTime";
import LeaderboardMonthly from "./LeaderboardMonthly";
import LeaderboardWeekly from "./LeaderboardWeekly"; 
import LeaderboardDaily from "./LeaderboardDaily";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("alltime");
 
  return (
    <div className="Tabs">
      <ul className="nav">
        <TabNavItem title="All-Time" id="alltime" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem title="Monthly" id="monthly" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem title="Weekly" id="weekly" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem title="Daily" id="daily" activeTab={activeTab} setActiveTab={setActiveTab}/>
      </ul>
 
      <div className="outlet">
        <TabContent id="alltime" activeTab={activeTab}>
          <LeaderboardAllTime />
        </TabContent>
        <TabContent id="monthly" activeTab={activeTab}>
          <LeaderboardMonthly />
        </TabContent>
        <TabContent id="weekly" activeTab={activeTab}>
          <LeaderboardWeekly />
        </TabContent>
        <TabContent id="daily" activeTab={activeTab}>
          <LeaderboardDaily />
        </TabContent>
      </div>
    </div>
  );
};
 
export default Tabs;